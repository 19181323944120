import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Novelty } from '@/tracking/domain/novelty/Novelty';
import { NoveltyRepository } from '@/tracking/domain/novelty/NoveltyRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class NoveltyServices implements NoveltyRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_NOVELTY_SAVE: string = this.API_BASE_URI + localStorageModule().tracking.routes.API_NOVELTY_SAVE;

  async create(novelty: Novelty) {
    try {
      return await http.post(this.API_NOVELTY_SAVE, novelty);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
