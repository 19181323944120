import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Order } from '@/courier/domain/order/Order';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';

@Injectable()
export class OrderServices implements OrderRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_ORDER_SAVE: string = this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_SAVE;
  private readonly API_ORDER_UPDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_UPDATE;
  private readonly API_ORDER_FINDBYDATERANGE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_FINDBYDATERANGE;
  private readonly API_ORDER_FINDBYDATERANGEANDCUSTOMERID: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_FINDBYDATERANGEANDCUSTOMERID;
  private readonly API_ORDER_FINDBYPK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_FINDBYPK;
  private readonly API_ORDER_FIND_ALL_BY_MODULE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_FINDALL_BY_MODULE;
  private readonly API_DOCUMENT_FIND_ALL_BY_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_FINDALL_BY_TYPE;
  private readonly API_DOCUMENT_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_SAVE;
  private readonly API_QUOTATIONS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_QUOTATIONS_UPDATE;
  private readonly API_QUOTATIONS_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_QUOTATIONS_SAVE;
  private readonly API_FINDALL_WMS_ORDERS_BY_MODULE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FINDALL_WMS_ORDERS_BY_MODULE;
  private readonly API_FIND_WMS_ORDERS_BY_PK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FIND_WMS_ORDERS_BY_PK;
  private readonly API_ORDER_FIND_BY_TYPE_AND_STATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_FIND_BY_TYPE_AND_STATE;

  async create(order: any) {
    try {
      return await http.post<Order[]>(this.API_ORDER_SAVE, JSON.stringify(order));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(order: Order) {
    try {
      return await http._put<Order[]>(this.API_ORDER_UPDATE, JSON.stringify(order));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByDateRange(payload: any): Promise<Order[]> {
    try {
      return await http.get<Order[]>(this.API_ORDER_FINDBYDATERANGE + `${payload.from}/${payload.to}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByPk(payload: any): Promise<Order[]> {
    try {
      return await http.get<Order[]>(this.API_ORDER_FINDBYPK + `number=${payload.number}&typeOrder=${payload.type}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByDateRangeAndCustomerId(params: any): Promise<Order[]> {
    try {
      return await http.get<Order[]>(
        this.API_ORDER_FINDBYDATERANGEANDCUSTOMERID + `${params.from}/${params.to}/${params.id}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findAllByModule(payload: any): Promise<Order[]> {
    try {
      return await http.get<Order[]>(this.API_ORDER_FIND_ALL_BY_MODULE + `${payload.module}/${payload.orderType}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllByDocumentType(payload: any): Promise<Order[]> {
    try {
      return await http.get<Order[]>(this.API_DOCUMENT_FIND_ALL_BY_TYPE + `${payload}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveDocument(payload: Order): Promise<Order[]> {
    try {
      return await http.post<Order[]>(this.API_DOCUMENT_SAVE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveQuotation(payload: WmsQuotationOrder): Promise<any> {
    try {
      return await http.post<any>(this.API_QUOTATIONS_SAVE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async updateQuotation(payload: WmsQuotationOrder): Promise<any> {
    try {
      return await http._put<any>(this.API_QUOTATIONS_UPDATE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllQuotationsByModuleAndType(payload: any): Promise<any> {
    try {
      return await http.get<any>(this.API_FINDALL_WMS_ORDERS_BY_MODULE + `${payload.module}/${payload.orderType}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findWmsOrderByPk(payload: any): Promise<any> {
    try {
      return await http.get<Order[]>(
        `${this.API_FIND_WMS_ORDERS_BY_PK}number=${payload.number}&typeOrder=${payload.type}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByTypeAndState(payload: { type: string; state: string; page: number }): Promise<any> {
    try {
      return await http.get<Order[]>(
        `${this.API_ORDER_FIND_BY_TYPE_AND_STATE}${payload.type}/${payload.state}/${payload.page}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
