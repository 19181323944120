import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { getLocalstorageItem } from '../config/LocalStorageVariables';

Vue.use(VueRouter);

const isLogged = function(to: any, from: any, next: any) {
  const publicPages = ['/auth/login', '/accept-invitation'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = getLocalstorageItem('ACCESS_TOKEN_COMPANY');
  const dateExpiration: any = Number(getLocalstorageItem('DATE_EXPIRATION_COMPANY'));
  const date = new Date().getTime();
  if ((authRequired && !loggedIn) || date > dateExpiration) {
    // destroyToken();
    return next({ path: '/auth/login' });
  }
  return next();
};
const routes: Array<RouteConfig> = [
  { path: '/', redirect: { name: 'DashboardDefault' } },
  {
    path: '/accept-invitation',
    name: 'AcceptInvitation',
    component: () => import('@/settings/infrastructure/ui/components/user/ActivateUser.vue')
  },
  {
    path: '/contable',
    redirect(to) {
      window.location.href = process.env.VUE_APP_URL_CONTABLE ?? 'https://contable.aris-operations.com/';
      return to.path;
    }
  },
  {
    path: '/auth',
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c: any) => c('router-view')
    },
    children: [
      {
        path: 'loginN',
        name: 'LoginN',
        component: () => import('@/courier/infrastructure/ui/components/login/LoginN.vue')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/courier/infrastructure/ui/components/login/LoginN.vue')
      },
      {
        path: 'workspace-select',
        name: 'WorkspaceSelection',
        component: () => import('@/courier/infrastructure/ui/components/login/WorkspaceSelection.vue')
      },
      {
        path: 'forgetpassword',
        name: 'ForgetPassword',
        component: () => import('@/courier/infrastructure/ui/components/customer/ForgetPassword.vue')
      }
    ]
  },
  {
    path: '/documents/v2',
    name: 'DocumentsV2',
    component: () => import('@/core/components/dashboard/Body.vue'),
    children: [
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/courier/infrastructure/ui/components/documents/DetailDocument.vue')
      }
    ],
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    }
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'consolidation',
        name: 'Consolidation',
        component: () => import('@/courier/infrastructure/ui/components/consolidation/ConsolidationCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'quotationrequest',
        name: 'QuotationRequest',
        component: () => import('@/freight/infrastructure/ui/quotation/FreightRegisterCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'requests',
        name: 'Requests',
        component: () => import('@/freight/infrastructure/ui/quotation/quotationList/FreightList.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'quotation',
        name: 'Quotation',
        component: () => import('@/freight/infrastructure/ui/quotation/FreightQuotationCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/freight',
    name: 'Freight',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'shipment',
        name: 'Shipment',
        component: () => import('@/freight/infrastructure/ui/shipment/ShipmentCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'shipmentList',
        name: 'ShipmentList',
        component: () => import('@/freight/infrastructure/ui/shipment/ShipmentList.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'timeline',
        name: 'Timeline',
        component: () => import('@/courier/infrastructure/ui/components/order/OrderHistory.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'invoicehistory',
        name: 'InvoiceHistory',
        component: () => import('@/freight/infrastructure/ui/invoice/InvoiceHistory.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'operationbyswitch',
        name: 'OperationbySwitch',
        component: () => import('@/freight/infrastructure/ui/components/responsive/OperationbySwitch.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'commodity',
        name: 'Commodity',
        component: () => import('@/freight/infrastructure/ui/commodity/CommodityCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'default',
        name: 'DashboardDefault',
        component: () => import('@/core/components/shared/DashboardDefault.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'historyPanel',
        name: 'historyPanel',
        component: () => import('@/wms/infrastructure/ui/historyPanel/HistoryPanel.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'schedule',
        name: 'schedule',
        component: () => import('@/tracking/infrastructure/ui/components/schedule/ScheduleManagement.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'freight-stock',
        name: 'freightStock',
        component: () => import('@/wms/infrastructure/ui/FreightStockDashboard/FreightStockDashboard.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/courier',
    name: 'Courier',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'quotation',
        name: 'CreateQuotation',
        component: () => import('@/courier/infrastructure/ui/components/quotation/CreateQuotationN.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'orderA',
        name: 'CreateOrderA',
        component: () => import('@/courier/infrastructure/ui/components/order/CreateOrder.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'order',
        name: 'CreateOrder',
        component: () => import('@/courier/infrastructure/ui/components/order/CreateOrderN/CourierOrderContainer.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'assistedorder',
        name: 'CreateAssistedOrder',
        component: () => import('@/courier/infrastructure/ui/components/order/CreateOrderN/CreateAssistedOrder.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'orderhistory',
        name: 'OrderHistory',
        component: () => import('@/courier/infrastructure/ui/components/order/OrderHistory.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'invoicehistory',
        name: 'InvoiceHistory',
        component: () => import('@/courier/infrastructure/ui/components/invoice/InvoiceHistory.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'operationbyswitch',
        name: 'OperationbySwitch',
        component: () => import('@/courier/infrastructure/ui/components/responsive/OperationbySwitch.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'menu',
        name: 'Menu',
        component: () => import('@/settings/infrastructure/ui/components/licenseManagement/MenuCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'layout-store',
        name: 'layoutStore',
        component: () => import('@/settings/infrastructure/ui/components/warehouse/storeLayout/StoreLayoutStore.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'Branch',
        name: 'BranchCenter',
        component: () => import('@/settings/infrastructure/ui/components/Branch/BranchComponentCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'menu/licenses',
        name: 'Licenses',
        component: () => import('@/settings/infrastructure/ui/components/licenseManagement/LicenseCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'emailTypes',
        name: 'EmailTypes',
        component: () => import('@/settings/infrastructure/ui/components/email_types/EmailTypeCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'personalizationTypes',
        name: 'PersonalizationTypes',
        component: () => import('@/settings/infrastructure/ui/components/customLoginImages/LoginImageCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/wms/infrastructure/ui/reference/SettingReferences.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'currency',
        name: 'Currency',
        component: () => import('@/settings/infrastructure/ui/components/currency/RegisterCurrencyCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'shippingMethods',
        name: 'ShippingMethods',
        component: () => import('@/settings/infrastructure/ui/components/shippingMethod/ShippingMethodCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'product_details',
        name: 'Product Detail',
        component: () => import('@/wms/infrastructure/ui/reference/References.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'pricingZones',
        name: 'pricingZones',
        component: () => import('@/settings/infrastructure/ui/components/zones/PrincingZonesCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'user-management',
        name: 'UserManagement',
        component: () => import('@/settings/infrastructure/ui/components/user_management/UserManagement.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('@/settings/infrastructure/ui/components/user_management/UserProfile.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'switch-management',
        name: 'SwitchManagement',
        component: () => import('@/settings/infrastructure/ui/components/switchs/SwitchManagement.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'ports',
        name: 'PortCenter',
        component: () => import('@/settings/infrastructure/ui/components/ports/PortCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'container-register',
        name: 'ContainerRegister',
        component: () => import('@/settings/infrastructure/ui/components/container/ContainerCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'inspection-procedures',
        name: 'ContainerInspection',
        component: () => import('@/settings/infrastructure/ui/components/container/ContainerInspection.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'packing-units',
        name: 'PackingUnits',
        component: () => import('@/settings/infrastructure/ui/components/packing_units/PackingUnitsCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'companysetting',
        name: 'CompanySetting',
        component: () => import('@/settings/infrastructure/ui/components/company/Settings.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'entity',
        name: 'Entity',
        component: () => import('@/settings/infrastructure/ui/components/entity/Entity.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/settings/infrastructure/ui/components/role/Role.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'createuser',
        name: 'CreateUser',
        component: () => import('@/settings/infrastructure/ui/components/user/CreateUser.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'createvolumeandweight',
        name: 'CreateVolumeAndWeight',
        component: () => import('@/settings/infrastructure/ui/components/volumeandweight/CreateVolumeAndWeight.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'typeoperation',
        name: 'TypeOperation',
        component: () => import('@/tracking/infrastructure/ui/components/typeoperation/TypeOperationCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'unitofmeasurement',
        name: 'UnitOfMeasurement',
        component: () => import('@/settings/infrastructure/ui/components/unitofmeasurement/UnitOfMeasurement.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'productivitycenter-management',
        name: 'ProductivityCenter',
        component: () => import('@/settings/infrastructure/ui/components/productivity_center/ProductivityCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'commodity-class',
        name: 'CommodityClass',
        component: () => import('@/settings/infrastructure/ui/components/commodityClass/CommodityClassCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/wms_nuevo',
    name: 'wms',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'sizes-and-colors',
        name: 'SizesAndColors',
        component: () => import('@/wms/infrastructure/ui/reference/SizesAndColorsCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'picking-packing',
        name: 'pickingPacking',
        component: () => import('@/wms/infrastructure/ui/PickingAndPacking/PickingAndPackingCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'warehouse-locate',
        name: 'warehouseLocate',
        component: () => import('@/wms/infrastructure/ui/warehouseLocate/WarehouseLocateCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'warehouse-cube',
        name: 'warehouseCube',
        component: () => import('@/wms/infrastructure/ui/warehouseCube/WarehouseCubeCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'warehouse-tagging',
        name: 'warehouseTagging',
        component: () => import('@/wms/infrastructure/ui/WarehouseTagging/WarehouseTaggingCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'reception',
        name: 'reception',
        component: {
          render: (c: any) => c('router-view')
        },
        children: [
          {
            path: 'operation-register',
            name: 'operationRegister',
            component: () => import('@/wms/infrastructure/ui/operationsRegister/OperationRegisterCenter.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'operations-list',
            name: 'operationsList',
            component: () => import('@/wms/infrastructure/ui/operationsList/OperationsList.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'warehouse-preregister',
            name: 'warehousePreRegister',
            component: () => import('@/wms/infrastructure/ui/warehousePreRegister/WarehousePreRegisterCenter.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'warehouse-preregister-list',
            name: 'warehousePreRegisterList',
            component: () =>
              import('@/wms/infrastructure/ui/warehousePreRegister/preRegisterList/WarehousePreRegisterList.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'warehouse-receipt',
            name: 'WarehouseReceipt',
            component: () => import('@/wms/infrastructure/ui/warehouseRecepit/WarehouseReceiptCenter.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          }
        ]
      },
      {
        path: 'expedition',
        name: 'expedition',
        component: {
          render: (c: any) => c('router-view')
        },
        children: [
          {
            path: 'operation-register',
            name: 'operationRegister',
            component: () => import('@/wms/infrastructure/ui/expedition/schedule/ScheduleExpedition.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'operations-list',
            name: 'operationsList',
            component: () => import('@/wms/infrastructure/ui/expedition/ManageSchedule/ManageExpeditionSchedule.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'warehouse-preregister',
            name: 'warehousePreRegister',
            component: () =>
              import('@/wms/infrastructure/ui/expedition/PreRegister/WarehouseExpeditionPreRegisterCenter.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'warehouse-preregister-list',
            name: 'warehousePreRegisterList',
            component: () =>
              import('@/wms/infrastructure/ui/warehousePreRegister/preRegisterList/WarehousePreRegisterList.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          },
          {
            path: 'warehouse-dispatch',
            name: 'warehouseDispatch',
            component: () => import('@/wms/infrastructure/ui/warehouseDispatch/WarehouseDispatchCenter.vue'),
            beforeEnter: function(to, from, next) {
              isLogged(to, from, next);
            }
          }
        ]
      },
      {
        path: 'addbillOfLanding',
        name: 'AddBillOfLanding',
        component: () => import('@/wms/infrastructure/ui/billOfLanding/BillOfLandingCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/settings/courier',
    name: 'SettingsCourier',
    component: () => import('@/core/components/dashboard/Body.vue'),
    children: [
      {
        path: 'territories',
        name: 'Territories',
        component: () => import('@/settings/infrastructure/ui/components/territories/ManageTerritorie.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'createwarehouse',
        name: 'CreateWarehouse',
        component: () => import('@/settings/infrastructure/ui/components/warehouse/RegisterWarehouse.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'taxes',
        name: 'Taxes',
        component: () => import('@/settings/infrastructure/ui/components/taxes/RegisterTaxes.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'createroute',
        name: 'CreateRoute',
        component: () => import('@/settings/infrastructure/ui/components/routes/RoutesCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'pricelist',
        name: 'PriceLists',
        component: () => import('@/settings/infrastructure/ui/components/pricelist/PriceList.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'courier-price-list',
        name: 'CourierPriceList',
        component: () => import('@/settings/infrastructure/ui/components/pricelist/PriceListN.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'eventscourier',
        name: 'EventCourier',
        component: () => import('@/tracking/infrastructure/ui/components/courier/events/ManageEvents.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'timeline',
        name: 'TimeLine',
        component: () => import('@/tracking/infrastructure/ui/components/courier/timeline/ManageTimeline.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/settings/wms',
    name: 'SettingsWms',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'eventswms',
        name: 'EventsWms',
        component: () => import('@/tracking/infrastructure/ui/components/wms/events/ManageEventsWms.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'timeline',
        name: 'TimeLineWms',
        component: () => import('@/tracking/infrastructure/ui/components/wms/timeline/ManageTimelineWms.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/settings/ff',
    name: 'SettingsFF',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'treedynamic',
        name: 'TreeDynamic',
        component: () => import('@/core/components/shared/TreeViewDynamic.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'createthird',
        name: 'CreateThird',
        component: () => import('@/tracking/infrastructure/ui/components/ff/third/CreateThird.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'eventsFF',
        name: 'EventsFF',
        component: () => import('@/tracking/infrastructure/ui/components/ff/events/ManageEventsFF.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'timelineFF',
        name: 'TimeLineFF',
        component: () =>
          import('@/tracking/infrastructure/ui/components/ff/timeline/manageTimeline/ManageTimelineFFN.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      }
    ]
  },
  {
    path: '/wms',
    name: 'Wms',
    component: () => import('@/core/components/dashboard/Body.vue'),
    beforeEnter: function(to, from, next) {
      isLogged(to, from, next);
    },
    children: [
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/wms/infrastructure/ui/reference/SettingReferences.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'product_details',
        name: 'Product Detail',
        component: () => import('@/wms/infrastructure/ui/reference/References.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'layout',
        name: 'Layout',
        component: () => import('@/wms/infrastructure/ui/Layout/LayoutStore.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentOrder',
        name: 'TypeDocumentOrder',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentOrder.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentCporder',
        name: 'TypeDocumentCporder',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentCporder.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentCreturns',
        name: 'TypeDocumentCreturns',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentCreturns.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentInvoicing',
        name: 'TypeDocumentInvoicing',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentInvoicing.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentRtsuppliers',
        name: 'TypeDocumentRtsuppliers',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentRtsuppliers.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentSpecialNotes',
        name: 'TypeDocumentSpecialNotes',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentSpecialNotes.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentClientQuotation',
        name: 'TypeDocumentClientQuotation',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentClientQuotation.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentSupplierQuotation',
        name: 'TypeDocumentSupplierQuotation',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentSupplierQuotation.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'TypeDocumentWhreceipt',
        name: 'TypeDocumentWhreceipt',
        component: () => import('@/wms/infrastructure/ui/docs/TypeDocumentWhreceipt.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'listDocuments',
        name: 'ListDocuments',
        component: () => import('@/wms/infrastructure/ui/docs/ListComponent/ListDocumentContainer.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'inventoryDashboard',
        name: 'InventoryDashboard',
        component: () => import('@/wms/infrastructure/ui/stock/InventoryDashboard.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'documentsConsultation',
        name: 'DocumentsConsultation',
        component: () => import('@/wms/infrastructure/ui/documentsConsultation/DocumentsConsultation.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'warehouseAdministrationCenter',
        name: 'WarehouseAdministrationCenter',
        component: () => import('@/wms/infrastructure/ui/warehouseCenter/WarehouseAdministrationCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'transportDocumentList',
        name: 'TransportDocumentList',
        component: () => import('@/wms/infrastructure/ui/documentsConsultation/TransportDocumentList.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
      {
        path: 'preparation',
        name: 'preparation',
        component: () => import('@/freight/infrastructure/ui/preparation/PreparationCenter.vue'),
        beforeEnter: function(to, from, next) {
          isLogged(to, from, next);
        }
      },
    ]
  }
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    return new Promise(resolve => {
      const position = (function() {
        if (savedPosition) {
          return savedPosition;
        } else {
          if (to.hash) {
            return {
              selector: to.hash
            };
          }
        }
      })();
      router.app.$root.$once('triggerScroll', () => {
        router.app.$nextTick(() => resolve(position));
      });
    });
  }
});
export default router;
