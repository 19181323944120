const localStorageVariables = {
  OPERATIONAL_PROFILES: 'operationalProfiles',
  CONS_AUTO_REFERENCE: 'consAutoReference',
  ACCESS_TOKEN_COMPANY: 'accessTokenCompany',
  USER_ID_COMPANY: 'userIdCompany',
  USER_NAME_COMPANY: 'userNameCompany',
  DATE_EXPIRATION_COMPANY: 'dateExpirationCompany',
  USER_EMAIL_COMPANY: 'userEmailCompany',
  USER_ROL: 'userRol',
  LOGO_USER: 'logoUser',
  BUSINESS_ID: 'businessId',
  CUSTOMER_ID: 'customerId',
  COMPANY_DATA: 'companyData',
  DATA_MODULE_COMPANY: 'dataModuleCompany',
  PROFILE: 'profile',
  MENU_USER: 'menuUser',
  USER_NAME: 'userName',
  USER_EMAIL: 'userEmail'
};

type LocalStorageVariables = typeof localStorageVariables;

const getLocalstorageItem = (key: keyof LocalStorageVariables) => {
  return localStorage.getItem(localStorageVariables[key]);
};

export { getLocalstorageItem };

export default localStorageVariables;
