import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { MenuRepository } from '@/settings/domain/menu/MenuRepository';
import { Menu } from '@/settings/domain/menu/menu';
import { Inject } from 'inversify-props';

@Injectable()
export class DeleteMenu extends Query<Promise<Menu>, number> {
  public constructor(
    @Inject(TYPES.MENU_REPOSITORY)
    private readonly repository: MenuRepository
  ) {
    super();
  }

  async internalExecute(id: number): Promise<Menu> {
    const data = await this.repository.delete(id);
    return data;
  }
}
